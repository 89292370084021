textarea {
  resize: none;
  height: 200px;
}

#contact-section {
  background-color: #f9f9f9;
  margin: 0 auto;
  padding: 20px;
  background: url("../assets/images/ContactFondo.png") no-repeat center center
    fixed;
  background-size: cover;
  color: #ffff;
}

.modal-content p {
  color: black;
  text-align: center;
}

@media (min-width: 850px) {
  #contact-map {
    height: 80vh;
    width: 500px;
  }

  .contact-form {
    width: 60%;
    min-width: 300px;
    padding: 20px;
    margin-right: 10px;
    justify-content: center;
  }
}

@media (max-width: 768px) {

  #contact-section {
    padding-top: 85px;
  }

  #contact-section-content {
    height: auto;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  .contact-form {
    display: block;
    height: auto;
    margin-bottom: 15px;
    width: 100%;
    padding: 20px;
  }

  #contact-map {
    padding-top: 20px;
    height: 400px;
    width: 100%;
    margin-bottom: 20px;
  }

  .form-row {
    display: block;
    flex-wrap: wrap;
  }

  .form-group {
    width: 100%;
  }

  .form-group input[type="text"],
  .form-group input[type="email"],
  .form-group input[type="tel"],
  .form-group textarea {
    width: 100%;
    max-width: 100%;
  }

  #contact-map,
  .contact-form {
    margin: 0 auto;
  }
}

@media (max-width: 1024px) {
  #contact-section-content {
    height: auto;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  .form-row {
    display: block;
    flex-wrap: wrap;
  }

  .form-group {
    width: 100%;
  }

  .form-group input[type="text"],
  .form-group input[type="email"],
  .form-group input[type="tel"],
  .form-group textarea {
    width: 100%;
    max-width: 100%;
  }

  #contact-map,
  .contact-form {
    margin: 0 auto;
  }

  .contact-form {
    display: block;
    height: auto;
    min-width: 350px;
    margin-bottom: 15px;
    width: 100%;
    padding: 20px;
  }

  #contact-map {
    padding-top: 20px;
    height: 400px;
    width: 100%;
    margin-bottom: 20px;
  }
}

#contact-section-content {
  display: flex;
  flex-wrap: wrap; /* Ensure items wrap onto a new line on smaller screens */
  justify-content: space-around; /* Adjust spacing between items */
  max-width: 1140px;
  margin: 0 auto;
  color: black;
  font-weight: bold;
  border-radius: 10px;
}


.contact-form {
  border-radius: 5px;
  background-color: rgba(225, 239, 244, 1);
  min-width: 270px;
}

#contact-section .contact-form-heading {
  font-size: 24px;
  margin-bottom: 15px;
}

#contact-section h4.contact-form-heading {
  font-size: 18px;
  margin-bottom: 20px;
}

#contact-section-content {
  margin: 0 auto;
  display: flex;
  flex-wrap: nowrap;
  max-width: 1140px;
  justify-content: space-around;
}

.form-row {
  display: flex;
  justify-content: space-around;
}

.form-group {
  width: 100%;
  margin-bottom: 20px;
  padding-left: 5px;
  padding-right: 5px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  text-align: left;
}

.form-group input[type="email"],
.form-group input[type="text"],
.form-group input[type="tel"],
.form-group textarea {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  border-radius: 3px;
  font-family: inherit; /* Ensure consistent font family */
}

.form-group input:focus,
.form-group textarea:focus {
  outline: none; /* Remove default focus outline */
  border-color: #ccc; /* Optional: Set a neutral border color when focused */
  box-shadow: none; /* Optional: Remove any box shadow when focused */
}

.contact-form button[type="submit"] {
  background-color: #162635;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 3px;
  transition: background-color 0.3s ease;
}

.contact-form button[type="submit"]:hover {
  background-color: #253f57; /* Slightly darker blue on hover */
}

